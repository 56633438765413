<template>
  <div>
    <aForm @submit.prevent="onSubmitForm" :form="form">
      <aRow :gutter="20">
        <aCol :span="24">
          <HayaTextField
            label="Título da documentação"
            placeholder="Escreva..."
            fieldKey="title"
            :required="true"
            :form="form"
            :preIcon="true"
          />
        </aCol>

        <aCol :span="24">
          <a-form-item class="travel-input-outer">
            <label class="filled">Tecnologias</label>
            <aSelect
              class="travel-input"
              placeholder="Selecione"
              mode="multiple"
              v-decorator="[
                `technologies`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Selecione',
                    },
                  ],
                },
              ]"
              show-search
              style="width: 100%"
            >
              <a-select-option
                v-for="(item, index) of technologiesList"
                :key="index"
                :value="item.value"
              >
                {{ item.value }}
              </a-select-option>
            </aSelect>
          </a-form-item>
        </aCol>

        <aCol :span="24">
          <a-form-item class="travel-input-outer">
            <label class="filled">Websites</label>
            <aSelect
              class="travel-input"
              placeholder="Selecione"
              mode="multiple"
              v-decorator="[
                `websites`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Selecione',
                    },
                  ],
                },
              ]"
              show-search
              style="width: 100%"
            >
              <a-select-option
                v-for="(item, index) of websitesList"
                :key="index"
                :value="item.value"
              >
                {{ item.value }}
              </a-select-option>
            </aSelect>
          </a-form-item>
        </aCol>

        <aCol :span="24">
          <a-button
            class="page-button"
            type="primary"
            html-type="submit"
            block
            :loading="loading"
            :disabled="loading"
          >
            Cadastrar
          </a-button>
        </aCol>
      </aRow>
    </aForm>
  </div>
</template>

<script>
import HayaTextField from "@/components/general/fields/HayaTextField.vue";

export default {
  name: "CreateDocumentationModal",
  components: { HayaTextField },
  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
      technologiesList: [],
      websitesList: [],
    };
  },
  mounted() {
    this.$http
      .get(
        `/category-entry/list?page=1&per_page=500&category_id=35&order-by=name&order=ascend`
      )
      .then(({ data }) => {
        this.technologiesList = data.data.map(({ name }) => {
          return {
            label: `${name}`,
            value: name,
          };
        });
      });

    this.$http
      .get(
        `/category-entry/list?page=1&per_page=500&category_id=34&order-by=name&order=ascend`
      )
      .then(({ data }) => {
        this.websitesList = data.data.map(({ name }) => {
          return {
            label: `${name}`,
            value: name,
          };
        });
      });
  },
  methods: {
    onSubmitForm(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.user_id = this.$store.state.userData.id;
        values.technologies = JSON.stringify(values.technologies);
        values.websites = JSON.stringify(values.websites);
        console.log(err, values);
        if (!err) {
          this.$http
            .post("/documentation/create", values)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.$http.post("/log/create", {
                user_id: this.$store.state.userData.id,
                module_id: data.id,
                module: "documentation",
                action: "create",
                description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} cadastrou uma nova documentação ID ${data.id}.`,
              });
              this.loading = false;
              this.$emit("onCreateDocumentation");
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped></style>
